@import "~@/styles/variables/variables.scss";

































































































































































































































































































































::v-deep {
	.el-date-editor {
		width: 400px !important;
	}
}
.query {
	padding-top: 10px;
	padding-bottom: 20px;
	.btn {
		margin-left: 20px;
		height: 32px;
	}
}
.content {
	height: 450px;
	@include flex-center;
	.echart {
		width: 100%;
		height: 100%;
	}
	.gc-blank-page {
		margin: auto;
	}
}
